import React, { Component } from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
import ImageLoader from "./image-loader";
//
class Image extends Component {
  state = {
    bounds: null
  };

  refCallback = element => {
    if (element) {
      const { height, width } = element.getBoundingClientRect();
      this.setState({
        bounds: {
          height,
          width
        }
      });
    }
  };

  render() {
    const { caption, dimensions, url, ...rest } = this.props;
    const { bounds } = this.state;

    return (
      <Outer>
        <Wrap ref={this.refCallback} {...dimensions} {...rest}>
          {bounds && <ImageLoader bounds={bounds} {...this.props} />}
        </Wrap>
        {caption && <RichText {...caption} />}
      </Outer>
    );
  }
}

export default Image;
//
const Outer = styled.div`
  p {
    margin: 0.5rem 0 1rem;
  }
`;
const Wrap = styled.figure`
  background-color: rgba(0, 0, 0, 0.1);
  height: 0;
  margin: 0;
  overflow: hidden;
  padding-bottom: ${props => `${(100 * props.height) / props.width}%`};
  position: relative;
  width: 100%;
`;
