// ----------------------------------------
// Calculates the required size
// with custom settings
// and passes these to bg
// ----------------------------------------
import React from "react";
//
import BG from "./bg";
//
const withDimensions = (image, bounds) => {
  // Bounds or dimensions are null, return original image
  if (!bounds) {
    return Object.assign({}, image);
  }
  let dpi = 1;

  if (
    typeof document !== "undefined" &&
    window.devicePixelRatio !== "undefined"
  ) {
    dpi = window.devicePixelRatio;
  }

  return Object.assign({}, image, { dpi }, { dimensions: { ...bounds } });
};
//
export default ({ bounds, image, ...rest }) => {
  const smartImage = withDimensions(image, bounds);
  return <BG {...smartImage} {...rest} />;
};
