import React from "react";
// import PropTypes from 'prop-types';
import { Head } from "react-static";
// import Maybe from 'folktale/maybe';

export default props => {
  const { location, meta, site } = props;

  const url =
    location && site ? `${site.url}${location.pathname}${location.hash}` : null;

  const schema = meta && meta.schema ? meta.schema : "Article";

  return (
    <Head
      htmlAttributes={{
        lang: meta.lang,
        itemScope: undefined,
        itemType: `http://schema.org/${schema}`
      }}
    >
      {/* HTML */}
      <title>{meta.title ? `${meta.title} | ${site.title}` : site.title}</title>
      <link rel="canonical" href={url} />

      {/* Icons */}
      <link rel="apple-touch-icon" sizes="180x180" href={site.favicon.x180} />
      <link rel="icon" type="image/png" sizes="32x32" href={site.favicon.x32} />
      <link rel="icon" type="image/png" sizes="16x16" href={site.favicon.x16} />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href={site.favicon.svg} color={site.themeColor} />
      <meta name="msapplication-TileColor" content={site.themeColor} />
      <meta name="theme-color" content={site.themeColor} />

      {/* Basics */}
      <meta itemProp="description" content={meta.description} />
      {meta.tags && <meta name="keywords" content={meta.tags} />}
      <meta itemProp="image" content={meta.image.url} />
      <meta itemProp="name" content={meta.title} />

      {/* OG */}
      {meta.author && <meta name="article:author" content={meta.author} />}
      {meta.published && (
        <meta name="article:published_time" content={meta.published} />
      )}
      {meta.updated && (
        <meta name="article:modified_time" content={meta.updated} />
      )}
      {meta.category && <meta name="article:section" content={meta.category} />}
      {meta.tags && <meta name="article:tag" content={meta.tags} />}
      <meta name="og:description" content={meta.description} />
      {site.determiner && (
        <meta property="og:determiner" content={site.determiner} />
      )}
      <meta name="og:image" content={meta.image.facebook.url} />
      <meta name="og:image:alt" content={meta.title || site.title} />
      {meta.image.facebook.dimensions && (
        <meta
          property="og:image:height"
          content={meta.image.facebook.dimensions.height}
        />
      )}
      {meta.image.facebook.dimensions && (
        <meta
          property="og:image:width"
          content={meta.image.facebook.dimensions.width}
        />
      )}
      <meta property="og:locale" content={meta.lang} />
      <meta name="og:image:secure_url" content={meta.image.facebook.url} />
      <meta name="og:site_name" content={site.title} />
      <meta name="og:title" content={meta.title || site.title} />
      {meta.contentType && <meta name="og:type" content={meta.contentType} />}
      <meta name="og:url" content={url} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      {meta.twitter && <meta name="twitter:creator" content={meta.twitter} />}
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image:src" content={meta.image.twitter.url} />
      {site.twitter && <meta name="twitter:site" content={site.twitter} />}
      <meta name="twitter:title" content={meta.title || site.title} />

      {/* Facebook */}
      {site.facebookAppID && (
        <meta name="fb:app_id" content={site.facebookAppID} />
      )}
      {site.facebookProfileID && (
        <meta name="fb:profile_id" content={site.facebookProfileID} />
      )}
    </Head>
  );
};
