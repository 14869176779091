import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
//
import Menu from "./menu";
import Products from "./products";
import Footer from "./footer";
//
export default ({ description, image, top_text, ...rest }) => (
  <Wrap>
    <Top>
      <Image>
        <img id="top" src={image.url} alt={image.alt} />
      </Image>
      <Text>
        <RichText {...top_text} />
      </Text>
    </Top>
    {/* <Menu {...rest} />
    <Products {...rest} />
    <Footer {...rest} /> */}
    {/* <pre>
      <code>{JSON.stringify(rest, null, 2)}</code>
    </pre> */}
  </Wrap>
);
//
const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;

  p {
    max-width: 600px;
  }
`;
const Top = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  justify-content: center;
  padding: 2rem 2rem 3rem 2rem;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
    padding-top: 5rem;
  }
`;
const Image = styled.div`
  justify-self: center;

  img {
    max-height: 45vh;
  }

  @media (min-width: 768px) {
    justify-self: end;

    img {
      // max-height: 444px;
    }
  }
`;
const Text = styled.div`
  padding: 1rem;
  text-align: center;

  img {
    margin: 0 auto;
    max-height: 130px;
  }
  h1 {
    margin: 0;
    padding: 1rem 0 0.5rem 0;
  }

  @media (min-width: 768px) {
    text-align: initial;
    h1 {
      margin: 0;
      padding: 1rem 0 0.1rem 0;
    }
  }
`;
