import React from "react";
import styled from "styled-components";
//
import RichText from "./index";
//
export default props => (
  <Wrap>
    <RichText {...props} />
  </Wrap>
);
//
const Wrap = styled.div`
  margin: 0 auto;
  max-width: 62rem;
`;
