// ----------------------------------------
// Render div with a background image,
// also takes care of the loading animation
// ----------------------------------------
import React from "react";
import PropTypes from "prop-types";
import ProgressiveImage from "react-progressive-image";
import styled from "styled-components";
//
const webp = () => {
  if (typeof document === "undefined") return false;
  const elem = document.createElement("canvas");
  if (elem.getContext && elem.getContext("2d")) {
    return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  }
  return false;
};
//
const BG = ({ dimensions, dpi, quality, mode, url, ...rest }) => {
  const { height, width } = dimensions;

  let q = quality;
  if (q < 0) {
    q = 75 - 10 * dpi;
  }

  const output = webp() ? "webp" : "jpg";
  const strippedUrl = url.replace(/^(https?:|)\/\//, "");
  // const src = `//images.weserv.nl/?url=${strippedUrl}&w=${width}&h=${height}&q=${q}&dpr=${dpi}&a=entropy&t=square&output=${output}`;
  // const src = `//images.weserv.nl/?url=${strippedUrl}&w=${width}&h=${height}&q=${q}&dpr=${dpi}&a=attention&t=square&output=${output}`;
  const src = `//images.weserv.nl/?url=${strippedUrl}&w=${width}&h=${height}&q=${q}&dpr=${dpi}&t=square&output=${output}`;

  return (
    <ProgressiveImage src={src}>
      {(src, loading) => <BGStyled loading={loading} src={src} {...rest} />}
    </ProgressiveImage>
  );
};

BG.defaultProps = {
  dpi: 1,
  quality: -1
};

BG.propTypes = {
  dpi: PropTypes.number,
  quality: PropTypes.number,
  mode: PropTypes.string,
  url: PropTypes.string.isRequired,
  dimensions: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }).isRequired
};

export default BG;
//
const BGStyled = styled.div`
  background-image: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${props => (props.contain ? "contain" : "cover")};
  bottom: 0;
  left: 0;
  opacity: ${props => (props.loading ? 0 : 1)};
  position: fixed;
  right: 0;
  top: 0;
  transition: ${props =>
    props.loading ? "opacity 420ms ease-in" : "opacity 0 ease-in;"};
`;
