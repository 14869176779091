import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
//
import Logo_neg from "components/SDP_Logo.png";
import Facebook from "components/svg/fontawesome/FacebookSquare";
import Linkedin from "components/svg/fontawesome/LinkedinSquare";
import Twitter from "components/svg/fontawesome/TwitterSquare";
import Mail from "components/svg/fontawesome/EnvelopeSquare";
//
export default ({ footer, ...rest }) => (
  <Wrap>
    {footer && <RichText {...footer} />}
    <Links>
      <Link>
        <a href="mailto:jr.arviointi@gmail.com">
          <Mail />
        </a>
      </Link>
      <Link>
        <a href="https://www.linkedin.com/in/jariritsila" target="_blank">
          <Linkedin />
        </a>
      </Link>
      <Link>
        <a href="https://www.facebook.com/jarrits" target="_blank">
          <Facebook />
        </a>
      </Link>

      <Link>
        <a href="https://twitter.com/jarrits?lang=en" target="_blank">
          <Twitter />
        </a>
      </Link>
    </Links>
    <a href="https://sdp.fi" target="_blank">
      <img src={Logo_neg} alt="SDP" />
    </a>
  </Wrap>
);
//
const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background-color: #f5c8c3;
  justify-item: center;
  padding: 4rem 1rem 2rem;
  text-align: center;
  width: 100vw;

  h1 {
    color: initial;
    font-family: "Be Vietnam", sans-serif;
    font-size: 2em;
    margin: 0;
  }
  h4 {
    color: initial;
    font-size: 1em;
    line-height: 1;
    padding: 0 0 1rem 0;
  }

  img {
    max-width: 50px;
    padding-top: 0.5rem;
  }

  a:hover {
    color: #6a5036;
  }

  @media (min-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const Links = styled.div`
  display: flex;
  margin: 0 auto;
`;
const Link = styled.div`
  width: 30px;
  height: auto;
  margin: 5px;
  svg {
    fill: #be0a37;
    width: 100%;
  
    :hover {
      fill: initial;
    }
`;
