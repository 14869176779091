import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
//
export default ({ items, ...rest }) => (
  <Wrap>
    {items.map((item, i) => (
      <ProductItem key={i} id={item.item_title}>
        <img
          src={item.item_image.mobile.url}
          alt={item.item_image.alt}
          class="mobile"
        />
        <img src={item.item_image.url} alt={item.item_image.alt} class="main" />
        <Text>
          <Title>{item.item_title}</Title>
          <Description>
            <RichText {...item.item_description} />
          </Description>
        </Text>
      </ProductItem>
    ))}
  </Wrap>
);
//
const Wrap = styled.div``;
const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 2rem 0;

  img {
    margin: 0 auto;
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    width: 100vw;
  }

  .main {
    display: none;
  }

  @media (min-width: 768px) {
    padding: 0;
    .main {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
`;
const Text = styled.div`
  justify-content: center;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr;

  background-color: #ffff;
  width: 100vw;
  z-index: 2000;

  p {
    width: 500x;
  }

  @media (min-width: 769px) {
    grid-template-columns: 1fr 2fr;
    padding: 1rem;
  }
`;
const Title = styled.h2`
padding: 0 2rem
margin: 0.5rem;
justify-self: center;

  &:after {
    background-color: currentColor;
    content: "";
    display: block;
    height: 2px;
    margin: 0.5rem 0
    width: 4rem;
  }

  @media (min-width: 769px) {
    justify-self: end;
  }
`;
const Description = styled.div`
  margin: 0 auto;
  padding: 1rem 0;
  p {
    max-width: 550px;
  }

  h3 {
    margin: 0 0.2rem;
  }

  @media (min-width: 769px) {
    margin: initial;
    padding: 2rem 1rem;
  }
`;
