import React from "react";
import { withRouteData } from "react-static";
//
import Homepage from "components/homepage";
import Template from "containers/template";
//

export default withRouteData(({ homepage }) => (
  <Template>
    <Homepage {...homepage} />
  </Template>
));
