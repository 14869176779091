import React from "react";
import { RouteData, SiteData } from "react-static";
//
import Seo from "./seo";
//
const CombineMeta = routeMeta => (
  <SiteData>
    {({ meta, site }) => <Seo site={site} meta={{ ...meta, ...routeMeta }} />}
  </SiteData>
);
//
export default () => (
  <RouteData>{({ meta }) => <CombineMeta {...meta} />}</RouteData>
);
