import React from "react";
import styled from "styled-components";
//
import Logo from "./render/logo";
import Image from "./render/image";
import ImageBg from "./render/image-bg";
import RichText from "./render/rich-text";
import ContainedRichText from "./render/rich-text/contained";
//
export { ContainedRichText, Logo, Image, ImageBg, RichText };
//
const componentTypes = {
  logo: Logo,
  image: Image,
  text: ContainedRichText
};
//
export const RenderSlice = ({ slice_type, ...rest }) => {
  const Component = componentTypes[slice_type];
  return (
    <SliceWrap>
      <Component {...rest} />
    </SliceWrap>
  );
};
//
export const SlizeZone = ({ slices }) =>
  !slices ||
  slices.map((slice, index) => <RenderSlice key={index} {...slice} />);
//
export default props => (
  <pre>
    <code>{JSON.stringify(props, null, 2)}</code>
  </pre>
);
//
const SliceWrap = styled.div`
  margin: 0 auto 1rem;
`;
