import React from 'react';
import styled from 'styled-components';
//
import { Image } from 'components/prismic';
//
export default ({ ...rest }) => (
  <Wrap>
    <Image {...rest} />
  </Wrap>
);
//
const Wrap = styled.div`
  margin: 0 auto;
  max-width: 600px;
  min-width: 160px;
  width: 20vw;
`;
