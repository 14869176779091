// ----------------------------------------
// Measures the Bacground size,
// passes the bounds to ImageLoader
// ----------------------------------------
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
//
import ImageLoader from "./image-loader";
//
class ImageBg extends Component {
  imageRef = React.createRef();

  state = {
    bounds: null
  };

  refCallback = element => {
    if (element) {
      const { height, width } = element.getBoundingClientRect();
      this.setState({
        bounds: {
          height,
          width
        }
      });
    }
  };

  render() {
    const { image, ...rest } = this.props;

    if (!image || !image.url) {
      return <Wrap ref={this.refCallback} />;
    }

    const { bounds } = this.state;

    return (
      <Wrap ref={this.refCallback}>
        {bounds && <ImageLoader image={image} bounds={bounds} {...rest} />}
      </Wrap>
    );
  }
}

ImageBg.propsTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    dimensions: PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default ImageBg;
//
const Wrap = styled.div`
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;
