import React from 'react';
import styled from 'styled-components';
//
const RichText = styled.div`
  .no-break {
    white-space: nowrap;
  }
`;

export default ({ content, ...rest }) => {
  if (!content) {
    return null;
  }

  const markup = { __html: content };
  return (
    <RichText className="richText" dangerouslySetInnerHTML={markup} {...rest} />
  );
};
